.imageSize{
    width: 22vw;
}
.topImage{
    top: 3rem;
}
.heightDivImages{
    height: 40vw;
}
.rotateY{
    transform: rotateY(180deg);
}
.w90{
    width: 80%;
}

@media (max-width: 1200px) {
    .w90{
        width: 90%;
    }
}
@media (max-width: 991.98px) {
    .w90{
        width: 100%;
    }
}
@media (max-width: 767.98px) {
    .imageSize{
        width: 50vw;
    }
    .heightDivImages{
        height: 80vw;
    }
}