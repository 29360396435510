/* screens */
@media (min-width: 1600px) {
    .col-xxxl-3{
      flex: 0 0 auto;
      width: 25%;
    }
  }
@media (max-width: 1300px) {
    .vh-130{
      min-height: 55rem;
    }
    .behind-swiper{
      height: 13rem;
    }
    .behind-swiper2{
      height: 30rem;
    }
    .float-swiper{
      top: 48rem!important;
    }
  }
@media (max-width: 1150px) {
  .rem-35{
    min-height: 35rem !important;
  }
  .vw-20{
    height: 22vw;
  }
  .rem-15{
    height: 13rem;
  }
  }
@media (max-width: 992px) {
  .float-swiper{
    top: 47rem!important;
  }
  .w-95{
    width: 100%;
  }
  .mt-5rem{
    margin-top: 5rem;
  }
  .vw-20{
    height: 32vw;
  }
  .rem-15{
    height: 14rem;
  }
  .vh-91{
    height: 91vh;
  }
  .container ,.container-xl ,.container-lg ,.container-md ,.container-sm{
    max-width: 100vw !important;
  }
  }
@media (max-width: 767.98px) {
    .h-70{
      height: 50vh;
    }
    .h-11{
      height: 7rem;
    }
    .header-sentence{
      font-size: small !important;
    }
    .header-about{
      font-size: large !important;
    }
    .vh-130{
      min-height: 60rem;
    }
    .behind-swiper{
      height: 15rem;
    }
    .behind-swiper2{
      height: 27rem;
    }
    .float-swiper{
      top: 42.4rem!important;
    }
    .header-img{
      height: 100%;
    }
    .vw-20{
      height: 40vw;
    }
    .services-wrapper .swiper-wrapper{
      width: 100%;
    }
    .rem-15{
      height: 18rem;
    }
    .square{
      aspect-ratio: 2/1;
    }
  }
@media (max-width: 575.98px) {
    .small{
      font-size: .6rem;
    }
    .h-11{
      height: 7rem;
    }
    .h-70{
      height: 40vh;
    }
    .header-sentence{
      font-size: x-small !important;
    }
    .header-about{
      font-size: 1rem !important;
    }
    .project .project-details .position-absolute{
      position: static !important;
    }
  }
@media (max-width: 500px) {
    .header-sentence{
      font-size: 2vw !important;
    }
    .header-name{
      font-size: 8vw !important;
    }
    .header-about{
      font-size: 3vw !important;
    }
    .project .project-details{
      min-height: 7vh;
    }
    .small{
      font-size: 0.8rem;
    }
  }
@media (min-height: 1300px) {
    .float-swiper{
      top: 49rem!important;
    }
  }