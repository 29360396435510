.top30vh{
    top: 30vh;
}
.top37vh{
    top: 37vh;
}
.bgLang{
    background-color: #0282BF;
    transition: 0.3s;
}
.bgLang:hover{
    background-color: #0272a7;
}
.zhightest{
    z-index: 999;
}
.toTopPostion{
    bottom: 2rem;
    right: 1rem;
    height: 2.3rem;
    width: 2.3rem;
}
.flip{
    transform: rotateY(180deg);
}