.circleLogo{
    height: 120px;
    width: 120px;
}
.top{
    top: -50px;
}
.font5{
    font-size: 1.3rem;
}
.font3{
    font-size: 1.7rem;
}
.h41{
  height: 41vh;
}

/* colors */
.bgMain{
  background-color: #043A70;
}

.bgSec{
  background-color: #7E8283;
}

/* screens */
@media (max-width: 1200px) {
  .font5 {
    font-size: 1.2rem; 
  }
  .font3 {
    font-size: 1.5rem; 
  }
  .circleLogo{
    height: 110px;
    width: 110px;
}
}
@media (max-width: 767.98px) {
    .font5 {
      font-size: 1rem; 
    }
    .font3 {
      font-size: 1.5rem; 
    }
    .h41{
      height: 12vh;
    }
    .circleLogo{
      height: 50px;
      width: 50px;
  }
    .top{
      top: -23px;
  }
  }
@media (max-width: 575.98px) {
    .font5 {
      font-size:0.8rem; 
    }
    .font3 {
      font-size: 1rem; 
    }
  }