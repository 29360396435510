.navHover:hover{
    background-color: var(--primary-color);
  }
.navPosition10vh{
  bottom: -5vh;
}
.navPosition2vh{
  top: 2vh;
  bottom: 0;
}
.navPositionn2vh{
  top: -2vh;
}
.vh17{
  height: 17vh;
}
.w11{
  width: 13rem;
}
.top5{
  top: 5rem;
}
.rem5{
  height: 5rem;
}

@media (max-width: 1300px) {
  .w11{
    width: 12rem;
  }
}
@media (max-width: 1100px) {
  .w11{
    width: 11rem;
  }
}
@media (max-width: 992px) {
  .navPosition10vh{
    bottom: unset;
    top: 0;
    position: fixed !important;
  }
  .rem5{
    height: auto;
  }
  .top5{
    top: 100%;
  }
  .navPosition2vh{
    top: 0;
    bottom: unset;
  }
  .w11{
    width: 11rem;
  }
}
@media (max-width: 767.98px) {
  .w11{
    width: 10rem;
  }
}
@media (max-width: 576px) {
  .w11{
    width: 8rem;
  }
}