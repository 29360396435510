@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  --primary-color: #ECAA1E; 
  --primary-coming-color: #ECAA1E; 
  --primary-color-light: #ECAA1E; 
  --primary-color-hover: #d3991d; 
  --secondary-color: #043A70;
  --secondary-color-hover: #042d56;
  --secondary-color-medium: rgba(4, 58, 112 ,0.8);
  --img-achievements-home: url('images/continuous-one-line-drawing-laboratory-600nw-2317899873.webp');
  --linear-gradient: linear-gradient(180deg, rgba(4, 58, 112 ,0.8), rgb(22, 21, 21,0.7 ));
  --linear-gradient-card-1: linear-gradient(180deg, rgba(4, 58, 112 ,0.3), rgba(4, 58, 112 ,0.5));
  --linear-gradient-card-2: linear-gradient(180deg, rgba(4, 58, 112 ,0.3), rgba(4, 58, 112 ,0.8));
  --branch-bg: url('images/view-laboratory-test-tube-samples-with-copy-space.webp');
}

:root.company {
  --primary-color: #A39056;
  --primary-company-color: #565C62;
  --primary-color-light: #c4aa5c;
  --primary-color-hover: #847547;
  --secondary-color: #7E8283;
  --secondary-color-hover: #717475;
  --secondary-color-medium: rgba(126, 130, 131 ,0.8);
  --img-achievements-home: url('images/pexels-pixabay-271667.webp');
  --linear-gradient: linear-gradient(180deg, rgba(126, 130, 131, 0.8), rgb(22, 21, 21,0.7 ));
  --linear-gradient-card-1: linear-gradient(180deg, rgba(126, 130, 131, 0.3), rgb(22, 21, 21,0.5 ));
  --linear-gradient-card-2: linear-gradient(180deg, rgba(126, 130, 131, 0.3), rgb(22, 21, 21,0.8 ));
  --branch-bg: url('images/pexels-thiagomobile-2317640.webp');
}
.container ,.container-xl ,.container-lg ,.container-md ,.container-sm{
  max-width: 90vw !important;
}
p{
  margin: 0;
}

a{
  text-decoration: none;
  color: black;
}

ul{
  list-style: none;
}
ul li::marker{
  color: var(--secondary-color) !important;
}
.no-select {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none; 
  -ms-user-select: none;
}

img {
  pointer-events: none;
  -webkit-user-drag: none; 
  -webkit-user-drag: none;
}
.square{
  aspect-ratio: 1/1;
}

.portrait{
  aspect-ratio: 4/3;
}

.portrait2{
  aspect-ratio: 8/3;
}

.list-style-disc{
  list-style: disc;
}
.list-style-auto{
  list-style: auto;
}

.trans5{
  transition: 0.5s;
}

.trans3{
  transition: 0.3s;
}

.font-small{
  font-size: 0.8em !important;
}

.cursor-pointer{
  cursor: pointer;
}

.cursor-grab{
  cursor: grab;
}

.object-cover{
  object-fit: cover;
}

.object-center{
  object-position: center !important;
}

/* home */
.bg-achievements-home{
  background-color: var(--secondary-color-medium);
}
.bg-branches{
  background-image: var(--branch-bg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(4px);
  background-attachment: fixed;
}
.bg-coming{
  background-color: var(--primary-coming-color);
}
.img-achievements-home{
  background-image: var(--img-achievements-home);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.header-img{
  background-image: url('images/featured-title-bg.webp');
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  height: 45vh;
}
.about-header-img{
  background-image: url('images/services/الاستشارات/1.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  filter: blur(2px);
}
.about-header-img2{
  background-image: url('images/services/الاستشارات/WhatsApp\ Image\ 2025-01-02\ at\ 14.55.42_fa4ce842.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.float-swiper{
  top: 46rem!important;
}

/* about */
.top-90{
  top: 90%;
}
.border-l{
  /* border-image: linear-gradient(to left, rgba(248, 249, 250,0.75) 90%, rgba(248, 249, 250,0));   */
  border-radius: 0 70px 70px 0;
  padding: 0px 20px;
}
.border-r{
  /* border-image: linear-gradient(to right, rgba(248, 249, 250,0.75) 90%, rgba(248, 249, 250,0));   */
  border-radius: 70px 0 0 70px;
  padding: 0px 20px;
}

.rotateY{
  transform: rotateY(180deg);
}
.behind-swiper{
  height: 10rem;
}
.behind-swiper2{
  height: 30rem;
}
.linear-gradient{
  background-image: var(--linear-gradient);
}
.linear-gradient-bg{
  background-image: linear-gradient(180deg, rgba(126, 130, 131 ,0.5), rgb(22, 21, 21,0.8 ));
}
.linear-gradient-card , .linear{
  background-image: var(--linear-gradient-card-1);
  transition: 0.3s;
}
.linear-gradient-card:hover{
  background-image: var(--linear-gradient-card-2);
}
/* contact us home */
.contact-us a:hover i ,.contact-us a:hover p{
  color: white !important;
}

/* Navbar */
.shadowed-text {
  text-shadow: 
    1px 1px 0 rgba(0, 0, 0, 1),
    -1px 1px 0 rgba(0, 0, 0, 1),
    1px -1px 0 rgba(0, 0, 0, 1),
    -1px -1px 0 rgba(0, 0, 0, 1);
}

/* heights & width */
.vh-50{
  height: 50vh;
}
.vw-20{
  height: 20vw;
}
.vw-70{
  width: 70vw;
}
.w-4{
  width: 2rem;
}
.rem-35{
  min-height: 40rem !important;
}
.rem-15{
  height: 15rem;
}

.h-91{
  min-height: 91vh;
}
.vh-91{
  height: 100vh;
}
.h-79{
  min-height: 79vh;
}
.h-fit{
  height: fit-content;
}
.w-fit{
  width: fit-content;
}
.w-max{
  width: max-content;
}
.circle-icon{
  height: 4.3rem;
  width: 4.3rem;
}
.small-circle-icon{
  height: 1.5rem;
  width: 1.5rem;
}
.rem-5{
  height: 5rem;
}
.mt-5rem{
  margin-top: 0rem;
}
.vh-9{
  height: 9vh;
}
.rem-61{
  min-height: 61rem;
}
.min-vh-20{
  min-height: 20vh !important;
}
.w-fit{
  width: fit-content;
}
.w-60{
  width: 60%;
}
.w-90{
  width: 90%;
}
.w-90imp{
  width: 90% !important;
}
.w-95{
  width: 95%;
}
.bottom-after{
  bottom: -3.3vh;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #fafafa; 
}
 
::-webkit-scrollbar-thumb {
  background: var(--primary-color); 
  border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color-hover); 
}

/* main */
.bg-main{
  background-color: var(--secondary-color);
}
.bg-main2{
  background-color: var(--primary-color) !important;
}
.bg-comingsoon{
  background-color: #565c62;
}
.text-main{
  color: var(--primary-color) !important;
}
.text-main2{
  color: var(--secondary-color) !important;
}
.bg-hover:hover{
  background-color: var(--secondary-color);
}
.bg-hover2:hover{
  background-color: var(--primary-color);
}
.btn-main{
  background-color: var(--primary-color);
  color: white !important;
  border-radius: 0.375rem;
  transition: background-color 0.4s;
}
.btn-main:hover{
  background-color: var(--primary-color-hover) !important;
}
.btn-main2{
  background-color: var(--primary-color);
  color: white !important;
  border-radius: 0.375rem;
  transition: background-color 0.2s;
}
.btn-main2:hover{
  background-color: var(--secondary-color) !important;
}
.btn-main3{
  background-color: var(--secondary-color) !important;
  color: white !important;
  border-radius: 0.375rem;
  transition: background-color 0.2s;
}
.btn-main3:hover{
  background-color: var(--secondary-color-hover) !important;
}

/* fonts  */
.roboto-font{
  font-family: "Roboto", serif !important;
}
.cairo-font{
  font-family: "Cairo", sans-serif !important;
}
.fw-900{
  font-weight: 900;
}
.text-main-light{
  color: var(--primary-color-light);
}

/* line heights */
.lh-1{
  line-height: 1;
}
.lh-md{
  line-height: 2.4;
}

/* borders */
.rounded-bottom-half{
  border-radius: 0% 0% 15% 15% /9%;
}
.border-main{
  border-color: var(--primary-color) !important;
}

/* navbar */
.navbar-nav .nav-link.active {
  background: var(--primary-color) !important; 
  color: white !important;
}

/* navlinks */
.nav-link-collapse {
  color: var(--secondary-color) !important;
  position: relative;
}

.nav-link-collapse:after {
  content: '';
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: 0;
  height: 2px;
  background-color: var(--primary-color);
  transition: 0.4s;
}

.nav-link-collapse:hover:after,
.nav-link-collapse:focus:after,
.nav-link-collapse:focus-visible:after,
.nav-link-collapse.active:after {
  left: 0%;
  right: 0%;
}

.nav-link-collapse:focus,
.nav-link-collapse:focus-visible,
.nav-link-collapse.active {
  color: var(--primary-color) !important;
}

/* background Images */
.bg-black-40{
  background-color: rgba(0, 0, 0, 0.4);
}
.bg-black-70{
  background-color: rgba(0, 0, 0, 0.7);
}
.bg-black-80{
  background-color: rgba(0, 0, 0, 0.8);
}
.line-sentence{
  width: 8vw;
}
.line:after{
  content: '';
  position: absolute;
  bottom: -10px;
  width: 40%;
  height: 3px;
  background-color: var(--primary-color);
}
.line2:after{
  content: '';
  position: absolute;
  bottom: -10px;
  width: 15%;
  height: 3px;
  background-color: var(--primary-color);
  inset-inline-start: var(--alignment, 0)
}
.line3:after{
  content: '';
  position: absolute;
  bottom: -10px;
  width: 100%;
  height: 3px;
  background-color: var(--primary-color);
  inset-inline-start: var(--alignment, 0)
}

.scale-action{
  transform: scale(1.1);
}

/* animations */
.scale{
  transform: scale(1);
  transition: 0.3s;
}
.scale:hover{
  transform: scale(1.02);
}
.cardd .scale2{
  transform: scale(1);
  transition: 0.3s;
}
.cardd:hover .scale2{
  transform: scale(1.1);
}
.filter-coloured{
  filter: grayscale(1);
  transition: 0.3s;
}
.filter-coloured:hover{
  filter: unset;
}
.cardd .top-line{
  border-top: 0px solid var(--primary-color) !important;
  transition: 0.3s !important;
}
.cardd:hover .top-line{
  border-top: 8px solid var(--primary-color) !important;
}
.text-hover:hover ,.branch:hover .text-hover {
  color: var(--primary-color) !important;
}
.text-hover2:hover{
  color: var(--primary-color-hover) !important;
}
.text-hover3:hover{
  color: white !important;
}
.parallax-element {
  transition: transform 0.5s ease-out;
}
.shadow-hover{
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0);
  transition: all 0.3s ease !important;
}
.shadow-hover:hover{
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.project:hover .text-hover{
  color: var(--primary-color) !important;
}
.project .project-details{
  min-height: 4rem;
}
.project:hover .project-details{
  min-height: 13rem;
}

/* swiper */
.swiper-wrapper{
  align-items: center;
}
.swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  border-radius: 0 !important;
}
.swiper-pagination {
  bottom: 0 !important;
}
.swiper-pagination-bullet-active {
  height: 20px !important;
  background-color: var(--primary-color) !important;
}
.slick-prev:before, .slick-next:before{
  color: var(--secondary-color) !important;
  font-size: 25px !important;
}

/* swiper-services */
.services-wrapper .swiper-wrapper{
  width: 38%;
  align-items: flex-start;
}

/* services cards */
.services-cards {
  --bg-color: #fff;
  --bg-color-light: #eef0f1;
  --text-color-hover: #4C5656;
  --box-shadow-color: rgba(255, 238, 186, 0.48);
}

.card-service {
  width: 100%;
  height: 100%;
  background: var(--bg-color-light);
  border-top-right-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 14px 26px rgba(0,0,0,0.04);
  transition: all 0.4s ease-out;
  text-decoration: none;
}

.card-service:hover {
  transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: 0 24px 36px rgba(0,0,0,0.11),
    0 24px 46px var(--box-shadow-color);
}

.card-service:hover .overlay {
  transform: scale(25) translateZ(0);
}

.card-service:hover .circle {
  border-color: var(--bg-color-light);
  background: var(--bg-color);
}

.card-service:hover .circle:after {
  background: var(--bg-color-light);
}

.card-service:hover p span {
  color: var(--primary-color);
}

.card-service:active {
  transform: scale(1) translateZ(0);
  box-shadow: 0 15px 24px rgba(0,0,0,0.11),
    0 15px 24px var(--box-shadow-color);
}

.card-service p ,.card-service p span  {
  color: var(--secondary-color-hover);
  z-index: 1;
  transition: color 0.4s ease-out;
}

.circle {
  width: 131px;
  height: 131px;
  border-radius: 50%;
  background: var(--bg-color-light);
  border: 2px solid var(--bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  transition: all 0.4s ease-out;
}

.circle:after {
  content: "";
  width: 114px;
  height: 114px;
  display: block;
  position: absolute;
  background: var(--bg-color);
  border-radius: 50%;
  transition: opacity 0.4s ease-out;
}

.circle img {
  z-index: 100;
  transform: translateZ(0);
}

.overlay {
  width: 20px;
  position: absolute; 
  height: 20px;
  border-radius: 50%;
  background: var(--bg-color);
  top: 28%;
  left: 47%;
  z-index: 0;
  transition: transform 0.4s ease-out;
}